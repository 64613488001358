import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import DeveloperBanner from "../components/DeveloperBanner/DeveloperBanner"
import LandingContent from "../components/LandingContent/LandingContent"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import Accordion from "../components/AccordianComponent/Accordion"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import OffplanContactDetails from "../components/OffplanContactDetails/OffplanContactDetails"
import DeveloperProperty from "../components/DeveloperProperty/DeveloperProperty"
import TopInvestmentArea from "../components/TopInvestmentArea/TopInvestmentArea"
import algoliasearch from "algoliasearch/lite"
import qs from "qs"
import { removeDataAttributes } from "../components/Common/utils"
import { Container } from "react-bootstrap"
import logoBlack from "../images/logo-white.svg"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"


const DeveloperDetailsTemplate = ({ data, pageContext }) => {
  const [pageData, setPageData] = useState(data?.strapiDeveloper)
  const [loading, setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string


  const [areaCount, setAreaCount] = useState(null)

  const areaItems = pageData?.add_module?.find(item => {
    if (item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE") {
      return item
    }
  })

  const areaData = areaItems?.items

  const developerProperty =
    data?.allStrapiNewDevelopments?.edges?.length > 0
      ? data?.allStrapiNewDevelopments?.edges
      : null

  const contactData =
    data?.allStrapiSiteConfig?.edges?.length > 0
      ? data?.allStrapiSiteConfig?.edges[0]?.node
      : ""

  // Initialize Algolia client
  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_API_KEY
  )
  const index = client.initIndex(
    process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENTS_INDEX_NAME
  )

  // Function to search in Algolia based on area
  async function searchByArea(item, developer) {
    try {
      const searchResult = await index.search(
        `${item?.title?.replace(/ /g, "-")?.toLowerCase()},${developer?.toLowerCase()}`
      )
      return searchResult.nbHits
    } catch (error) {
      console.error(`Error searching for area ${item.area}:`, error)
    }
  }

  // Map through items and perform searches
  async function performSearches(items) {
    const searchPromises = items?.map(item => searchByArea(item, pageData?.developer_name))
    const results = await Promise.all(searchPromises)
    setAreaCount(results)
  }

  // Execute the searches
  useEffect(() => {
    performSearches(areaData)
  }, [])

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        developer_image:"*",
        seo:"*",
        developer_banner:{
          populate:{
            image:"*",
            cta_1_link:{
              populate:{
                parent:"*"
              }
            },
            cta_2_link:{
              populate:{
                parent:"*"
              }
            },
            cta_3_link:{
              populate:{
                parent:"*"
              }
            }
          }
        },
        add_module: {
          on: {
            'page-modules.image-content': {
              populate: {
                left_right_block: {
                  populate:{
                  image:"*",
                  menu:{
                    populate:{
                      parent:"*"
                    }
                  }
                }
              }
              },
            },
            "components.title-desc":{
              populate:"*"
            },
            "page-modules.accordion":{
              populate:{
                add:{
                  populate:"*"
                }
              }
            },
            "page-modules.two-columns":{
                 populate:{
                  image:"*",
                  about_developer:"*",
                  menu:{
                    populate:{
                      parent:"*"
                    }
                  }
              }
            },
            "page-modules.custom-modules":{
              populate:{
                select_module:"*"
              }
            },
            "page-modules.static-card-items":{
              populate:{
                cards:{
                  populate:{
                    cta:{
                      populate:{
                        link:{
                          populate:{
                            parent:"*"
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            "page-modules.primary-module":{
              populate:{
                items:{
                  populate:{
                    image:"*",
                      cta:{
                        link:{
                          populate:{
                            parent:"*"
                          }
                        }
                      }
                      
                  }
                }
              }
            },
            
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );


  useEffect(() => {
    // Check if the preview query parameter is present
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/developers/${urlParams.get('strapi_id')}?${query}`

    // make this only when preview params available
    if (isPreview) {
      setIsPreviewEnabled(true)

      const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

      const fetchData = async () => {
        try {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });
          const getData = await response.json();
          const previewData=removeDataAttributes(getData)
          setLoading(false)
          setEnablePreview(false)
          let myPrevData;
          if(pageData){
            myPrevData = pageData
          } else {
            myPrevData = {};
            myPrevData.developer_image = {}
            myPrevData.developer_banner.image = {}
            myPrevData.add_module=[]
            myPrevData.id = urlParams.get('strapi_id')
          }
          if(previewData){
            //skip fields like id, which are dont need to be updated
            myPrevData.developer_banner = previewData?.developer_banner
            myPrevData.developer_banner.image = previewData?.developer_banner?.image
            myPrevData.developer_name = previewData?.developer_name
            myPrevData.developer_banner.banner_content = previewData?.developer_banner?.banner_content
            myPrevData.developer_banner.banner_title = previewData?.developer_banner?.banner_title
            myPrevData.developer_banner.cta_1_title = previewData?.developer_banner?.cta_1_title
            myPrevData.developer_banner.cta_1_custom_link = previewData?.developer_banner?.cta_1_custom_link
            myPrevData.developer_banner.cta_1_link = previewData?.developer_banner?.cta_1_link
            myPrevData.developer_banner.cta_2_link = previewData?.developer_banner?.cta_2_link
            myPrevData.developer_banner.cta_2_title = previewData?.developer_banner.cta_2_title
            myPrevData.add_module = previewData?.add_module
           
            //merge the new data into the original data so that we dont have to worry about the template flow
            setPageData(pageData => ({ ...pageData, myPrevData }));
            sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else {
      setLoading(false);
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }
  }, [])

  if(loading){
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  }
  if(enablePreview){
    return null
  }

  return (
    <Layout popularSearch={pageData?.select_popular_search?.title}>
      <DeveloperBanner isPreviewEnabled={isPreviewEnabled} pageData={pageData} />
      {pageData?.add_module?.map((item, i) => {
        return (
          <div>
            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
              item?.__component === "components.title-desc") && (
              <LandingContent isDeveloperDetails isPreviewEnabled={isPreviewEnabled} data={item} />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_TWO_COLUMNS" || item?.__component === "page-modules.two-columns")
              && (
              <ImageContentComponent
                isPreviewEnabled={isPreviewEnabled}
                data={item}
                pageData={pageData}
                isOffplanLanding
                isDeveloperDetails
              />
            )}


            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules") &&
              item?.select_module === "developer_property" && developerProperty?.length>0&&(
                <DeveloperProperty
                  moduleData={item}
                  offplanData={developerProperty}
                />
              )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules")&&
              item?.select_module === "developers_contact" && (
                <OffplanContactDetails
                  contactData={contactData?.contact_info}
                  moduleData={item}
                  isPreviewEnabled={isPreviewEnabled}
                />
              )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE"  ||
              item?.__component === "page-modules.primary-module") && (
               <Container> 
                <TopInvestmentArea
                  areaCount={areaCount}
                  areasData={item}
                  pageData={pageData}
                  isDeveloperDetails
                  isPreviewEnabled={isPreviewEnabled}
                />
              </Container>
            )}

            {(item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" ||
              item?.__component === "page-modules.accordion") && (
              <Accordion
                isPreviewEnabled={isPreviewEnabled}
                add_toggle={item?.add}
                title={item?.title}
                content={item?.content}
                isChangeBg
              />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" || 
              item?.__component === "page-modules.static-card-items") && (
              <LandingStaticCard isPreviewEnabled={isPreviewEnabled}
              isDeveloperDetails data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export default DeveloperDetailsTemplate


export const Head = ({ data }) => {
  const PageData = data?.strapiDeveloper

  let getImg= PageData?.developer_banner?.image?.url? PageData?.developer_banner?.image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""

  const getImage =
  PageData?.developer_banner?.image?.url
      ? PageData?.developer_banner?.image?.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && PageData?.ggfx_results?.length > 0
      ? PageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""

  // filter image with image size
  const getOGImg =
    filterImage?.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: PageData.strapi_id,
      field: "metaog",
      contentType: "developer"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //----
  const pageImage=getOGImg?.length>0?getOGImg[0].url: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

  const metaTitle = PageData?.Seo?.metaTitle ? `${ PageData?.Seo?.metaTitle} | ` :"";
  const pageTitle = PageData?.developer_banner?.banner_title ? `${PageData?.developer_banner.banner_title} | `:""

  //preview seo dynamic updates

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
    document.title =  `${preMetaTitle} | haus & haus`;
    }
  }, [preMetaTitle]);
  //

  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle ? metaTitle : pageTitle} description={PageData?.Seo?.metaDescription ? PageData?.Seo?.metaDescription : PageData?.title} 
      imageUrl={pageImage}
      />
  )
}

export const query = graphql`
  query ($page_id: Int, $developer: String!) {
    allStrapiNewDevelopments(filter: { developer: { eq: $developer } , publish: { eq: true }}) {
      edges {
        node {
          slug
          strapi_id
          crm_id
          price
          createdAt
          whatsapp
          images {
            strapi_json_value {
              url
            }
          }
          banner_title
          ggfx_results {
            src_cftle
            transforms {
              format
              url
              transform
            }
          }
          area
          expected_completion_date
          developer
        }
      }
    }

    allStrapiSiteConfig {
      edges {
        node {
          offplan_ratetext
          offplan_rating
          contact_info {
            title
            whatsapp_num
            email
            description {
              data {
                description
              }
            }
          }
        }
      }
    }

    strapiDeveloper(strapi_id: { eq: $page_id }) {
      developer_banner {
        banner_title
        image {
          url
        }
       
        banner_content {
          data {
            banner_content
          }
        }
        cta_2_link {
          slug
          strapi_parent {
            slug
          }
        }
        cta_1_custom_link
        cta_2_title
        cta_3_title
        cta_1_title
        cta_2_custom_link
        cta_1_link {
          slug
          strapi_parent {
            slug
          }
        }
      }
      Seo {
        metaTitle
        metaDescription
      }
      strapi_id
      ggfx_results {
        src_cftle
        transforms {
          format
          url
          transform
        }
      }
      developer_name
      slug
      developer_image {
        url
      }
      publish
      add_module {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          id
          __typename
          title
          description {
            data {
              description
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE {
          id
          __typename
          title
          items {
            title
            sub_title
            image {
              url
            }
          }
          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          id
          __typename
          title
          content {
            data {
              content
            }
          }
          add {
            title
            content {
              data {
                content
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_TWO_COLUMNS {
          __typename
          id
          cta_label
          description {
            data {
              description
            }
          }
          menu {
            slug
            strapi_parent {
              slug
            }
          }
          custom_link
          custom_link1
           cta_label1
           menu1 {
            slug
            strapi_parent {
              slug
            }
          }
          strapi_component
          title
          layout
          image {
            url
          }
          about_developer {
            content {
              data {
                content
              }
            }
            title
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          id
          __typename
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              custom_link
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              title
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          __typename
          select_module
          title
          content {
            data {
              content
            }
          }
          add_cta_item {
            link {
              slug
            }
            title
            content {
              data {
                content
              }
            }
          }
        }
      }
    }
  }
`
