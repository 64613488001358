import React from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import GGFXImage from "../../modules/GGFXImage"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./TopInvestmentArea.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const TopInvestmentArea = ({ areaCount, areasData, pageData,isDeveloperDetails, isSearchResult, isPreviewEnabled }) => {
  const { isMobile } = useDeviceMedia()
  const imageName = isSearchResult? "offplan-slider.primary_module_image.investment_image" : isDeveloperDetails ? "developer.primary_module_image.investment_image" 
  : "page.primary_module_image.investment_image"
  return (
    <div className="investment-area-wrapper">
        <div
          // animateIn="animate__slideInUp"
          // animateOnce
          // delay={100}
          // offset={10}
        >
          <div className="investment-content-sections">
            <h2>{areasData?.title}</h2>
            {(areasData?.content?.data?.content || (areasData?.content && isPreviewEnabled )) && 
            <p className="description">
              <ContentModule Content={areasData?.content?.data?.content || areasData?.content} />
            </p>
            }
            <CustomSlider
              noDots
              showArrows
              showMeThree
              iconchange
              slidecount={isMobile ? 1.8 : 6}
              className="invest-offplan-slider"
            >
              {areasData?.items?.length > 0 &&
                areasData.items.map((item, index) => {

                  const slug =isDeveloperDetails ? `${item?.title?.replace(/\s+/g, "-")?.toLowerCase()}-by-${pageData?.slug}`:
                   item?.title?.replace(/\s+/g, "-")?.toLowerCase()
                  const titletext = item?.sub_title ? `${item?.title} (${item?.sub_title})`:item?.title;
                  return (
                    <div
                      // className="investment-slider-item"
                      // animateIn="animate__slideInUp"
                      // animateOnce
                      // delay={index * 100}
                      // offset={100}
                    >
                      <div className="investment-slider-item">
                        <div className="image-section">
                          <Link
                            to={`/off-plan-properties/for-sale/in-${slug}/`}
                          >
                            {item?.image?.url && (
                              <GGFXImage
                                ImageSrc={item?.image}
                                altText={item?.title}
                                imagetransforms={pageData?.ggfx_results}
                                renderer="srcSet"
                                imagename={imageName}
                                strapiID={pageData?.strapi_id}
                              />
                            )}
                          </Link>
                        </div>
                        <Link
                          to={`/off-plan-properties/for-sale/in-${slug}/`}
                        >
                        <p className="area-name">{titletext}</p>
                        </Link>
                        {areaCount?.length > 0 && (
                          <p className="area-count">
                            {areaCount[index]} properties
                          </p>
                        )}
                      </div>
                    </div>
                  )
                })}
            </CustomSlider>
          </div>
        </div>
    </div>
  )
}

export default TopInvestmentArea
