import { Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/offplan_subscribe.json"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import DefaultForm from "../forms/default-form-layout"
import "./OffplanContactDetails.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanContactDetails = ({ contactData,moduleData,isPreviewEnabled }) => {
  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const description = moduleData?.content?.data?.content
  const whatAppText =
    "Hi, I've come across the haus & haus Group and would like further information from you."
  return (
    <section className={`offplancontact-details-wrapper`}>
      <Container>
        <div className="offplancontact-sections">
          <div className="content-sections">
            {(moduleData?.title || contactData?.title) && <h4>{moduleData?.title || contactData?.title}</h4>}
            {(description || contactData?.description?.data?.description || (moduleData?.content && isPreviewEnabled )) &&
            <p className="offplancontact-intro">
              <ContentModule
                Content={description || moduleData?.content || contactData?.description?.data?.description }
              />
            </p>
            }
            <div className="social-details">
              <div>
                <a
                  target="_blank"
                  href={`https://wa.me/971502304695?text=${whatAppText}`}
                >
                  <icon className="icon black-whatsapp-outline" />
                  <span>Click to WhatsApp</span>
                </a>
              </div>
              <div className="horizontal-line"></div>
              <div>
                <a className="email-id" href={`mailto:info@hausandhaus.com`}>
                  <icon className="icon black-mail-icon" />
                  <span>info@hausandhaus.com</span>
                </a>
              </div>
            </div>
          </div>
          <div className="form-section__footer offplan_footer-form">
            <DefaultForm fields={FormFields} sourceUrl={pageurl} />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default OffplanContactDetails
