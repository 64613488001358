import React,{useState} from 'react'
import GGFXImage from "../../modules/GGFXImage"
import ScrollAnimation from "react-animate-on-scroll"
import "./DeveloperBanner.scss"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/developer.json"
import DefaultForm from "../../components/forms/default-form-layout"


const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const DeveloperBanner = ({pageData,isPreviewEnabled}) => {
  const [show, setShow] = useState(false)
    
  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const customThankingMsg = `"Thank you for registering your interest. You will receive news and updates from haus & haus 
  about ${pageData?.developer_name} at the email you have provided"`

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
    const bannerData = pageData?.developer_banner
    const imagename = "developer.developer_banner_image.dev_image"
  return (
    <div className="developer-details-banner">
        <GGFXImage
            ImageSrc={bannerData?.image}
            altText={`${
              bannerData?.alternativeText
                ? bannerData?.alternativeText
                : bannerData?.banner_title
            } banner`}
            imagetransforms={pageData?.ggfx_results}
            renderer="bgImg"
            imagename={imagename}
            strapiID={pageData?.strapi_id}
            className="banner-img"
        />

      <div className="overlay-bg"></div>
            
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="banner-content-section">
            <div className="banner-content">
              <h1>{bannerData?.banner_title}</h1>
              {(bannerData?.banner_content?.data?.banner_content || (isPreviewEnabled && bannerData?.banner_content)) && 
              <p className="description">
                <ContentModule
                  Content={bannerData?.banner_content?.data?.banner_content || bannerData?.banner_content}
                />
              </p>
              }
              <div className="cta-section">
                {bannerData?.cta_1_title === "Register Your Interest"?
                  <>
                  <button onClick={handleModal} className="button button-filled-green">
                    {bannerData.cta_1_title}
                  </button>
                  <Modal
                    show={show}
                    // onHide={handleClose}
                    backdrop="static"
                    centered
                    dialogClassName="modal-popup-form developer-banner"
                  >
                    <Modal.Body>
                      <div className="popup-form-wrapper">
                        <div
                          className="close-modal"
                          onClick={() => handleClose()}
                        >
                          <i className="icon black-cancel-icon"></i>
                        </div>
                        <div>
                        <DefaultForm
                          fields={FormFields}
                          sourceUrl={pageurl}
                          formTitle={"Register Your Interest"}
                          customThankingMsg={customThankingMsg} 
                          developerName={pageData?.developer_name}
                        />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                
                  </>
                 :
                 bannerData?.cta_1_title&&(
                  <PreviewCTALink
                    class="button button-filled-green"
                    link={
                      bannerData.cta_1_link
                        ? bannerData.cta_1_link
                        : { external_link: bannerData?.cta_1_custom_link }
                    }
                    
                    title={bannerData.cta_1_title}
                    target_window={
                      bannerData?.cta_1_custom_link?.includes("http")
                        ? "new_window"
                        : bannerData.cta_1_link?.target_window
                    }
                  />
                 )
                } 


                {bannerData?.cta_2_title && 
                  <PreviewCTALink
                    class="button button-outline-white"
                    link={
                      bannerData.cta_2_link
                        ? bannerData.cta_2_link
                        : { external_link: bannerData?.cta_2_custom_link }
                    }
                    title={bannerData.cta_2_title}
                    target_window={
                      bannerData?.cta_2_custom_link?.includes("http")
                        ? "new_window"
                        : bannerData.cta_2_link?.target_window
                    }
                  />
                }
              </div>
            </div>
          </div>
        </Container>
      </ScrollAnimation>
    </div>

  )
}

export default DeveloperBanner